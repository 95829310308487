.ProfileOpenCon1 {
  display: none;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: 100%;
  /* height: 300px; */
  box-sizing: border-box;
  background: #e0e0e0;
  position: absolute;
  overflow: hidden;
  /* overflow-y: scroll; */
}
.ProfileOpenCon2 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: linear-gradient(180deg, #fff 10%, rgba(255, 255, 255, 0.1) 100%);
}
.ProfileOpenCon3 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProfileOpenCon4 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
  gap: 24px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.ProfileOpenCon4B {
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 500px;
  width: 100%; /* Ensure it doesn't overflow */
  box-sizing: border-box;
}

.ProfileOpenCon5 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 15px;
  background: #fff;
}

.ProfileOpenCon6 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-bottom: 1px solid var(--app-white, #fff);
}
.ProfileOpenCon6Text {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ProfileOpenCon6B {
  display: none;
  padding: 6px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid #ff8400;
}
.ProfileOpenCon6BText {
  flex: 1 0 0;
  color: #ff8400;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ProfileOpenCon7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.ProfileOpenInputCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.ProfileOpenInputCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ProfileOpenInputCon3 {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}
.ProfileOpenInputCon4 {
  display: flex;
  height: 23px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
}
.ProfileOpenInputText {
  padding: 2px 6px;
  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ProfileOpenCon8 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.ProfileOpenCon8LogOut {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.ProfileOpenCon8DeleteAccount {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: var(--Danger-color-red, #f00);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}
.ProfileOpenCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.ProfileOpenCon10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.ProfileOpenCon11 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.ProfileOpenFotoCon {
  /* width: 64px;
  height: 64px; */
  /* border-radius: 50px; */

  /* background: var(--app-gray-soft); */
  /* overflow: hidden; */

  position: relative;
}
.ProfileOpenFotoConB {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  border: solid 1px var(--app-gray-medium);
  background: var(--app-gray-soft);
  overflow: hidden;

  fill: #fafafa;
  stroke-width: 1px;
  stroke: var(--app-gray-medium, #6d6d6d);
}
.ProfileOpenFotoConB2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.ProfileOpenFoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* padding: 20px; */
  box-sizing: border-box;
}
.ProfileOpenCon11Text {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.ProfileOpenCon11DeleteButton {
  display: none;
  padding: 3;
  background: #ffffffd1;
  height: 14px;
  position: absolute;
  top: 0;
  right: 18%;
  border-radius: 3px;
  transform: translateX(50%);
  z-index: 0;
  cursor: pointer;
}
.ProfileOpenConContactNumberMessage {
  display: none;
  align-self: stretch;
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProfileOpenSellerProfileSaveButton {
  display: none;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-gold-yellow, #f4c542);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.ProfileOpenSellerProfileSaveButtonNoneAct {
  opacity: 50%;
}

.ProfileOpenInputMessage {
  display: none;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.ProfileOpenInputMessageSuccess {
  display: flex;
  color: var(--app-green, #3a7d44);
}
.ProfileOpenInputMessageError {
  display: flex;
  color: var(--Danger-color-red, #f00);
}
