.ProfileOpenCon1 {
  box-sizing: border-box;
  background: #e0e0e0;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  overflow: hidden;
}

.ProfileOpenCon2 {
  background: linear-gradient(#fff 10%, #ffffff1a 100%);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 6px 12px;
  display: flex;
}

.ProfileOpenCon3 {
  color: var(--app-charcoal-dark, #2b2b2b);
  background: linear-gradient(270deg, #fff 0%, #fff0 100%);
  border-radius: 5px;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.ProfileOpenCon4 {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden scroll;
}

.ProfileOpenCon4B {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  max-width: 500px;
  padding: 24px 12px;
  display: flex;
}

.ProfileOpenCon5 {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px;
  display: flex;
}

.ProfileOpenCon6 {
  border-bottom: 1px solid var(--app-white, #fff);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.ProfileOpenCon6Text {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ProfileOpenCon6B {
  border: 1px solid #ff8400;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 6px 24px;
  display: none;
}

.ProfileOpenCon6BText {
  color: #ff8400;
  text-align: center;
  letter-spacing: .2px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ProfileOpenCon7 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.ProfileOpenInputCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.ProfileOpenInputCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ProfileOpenInputCon3 {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .24px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ProfileOpenInputCon4 {
  border-bottom: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 23px;
  display: flex;
}

.ProfileOpenInputText {
  color: var(--app-gray-medium, #6d6d6d);
  flex: 1 0 0;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProfileOpenCon8 {
  flex-wrap: wrap;
  place-content: flex-start space-between;
  align-self: stretch;
  align-items: flex-start;
  row-gap: 12px;
  padding: 0 12px;
  display: flex;
}

.ProfileOpenCon8LogOut {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.ProfileOpenCon8DeleteAccount {
  color: var(--Danger-color-red, red);
  letter-spacing: .28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.ProfileOpenCon9 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.ProfileOpenCon10 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  display: flex;
}

.ProfileOpenCon11 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.ProfileOpenFotoCon {
  position: relative;
}

.ProfileOpenFotoConB {
  border: solid 1px var(--app-gray-medium);
  background: var(--app-gray-soft);
  fill: #fafafa;
  stroke-width: 1px;
  width: 64px;
  height: 64px;
  stroke: var(--app-gray-medium, #6d6d6d);
  border-radius: 50px;
  overflow: hidden;
}

.ProfileOpenFotoConB2 {
  flex-direction: column;
  align-items: center;
  gap: 6px;
  display: flex;
}

.ProfileOpenFoto {
  object-fit: cover;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.ProfileOpenCon11Text {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .16px;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProfileOpenCon11DeleteButton {
  z-index: 0;
  cursor: pointer;
  background: #ffffffd1;
  border-radius: 3px;
  height: 14px;
  padding: 3px;
  display: none;
  position: absolute;
  top: 0;
  right: 18%;
  transform: translateX(50%);
}

.ProfileOpenConContactNumberMessage {
  color: var(--app-green, #3a7d44);
  align-self: stretch;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: none;
}

.ProfileOpenSellerProfileSaveButton {
  background: var(--app-gold-yellow, #f4c542);
  color: var(--app-white, #fff);
  letter-spacing: .28px;
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: none;
}

.ProfileOpenSellerProfileSaveButtonNoneAct {
  opacity: .5;
}

.ProfileOpenInputMessage {
  letter-spacing: .16px;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
}

.ProfileOpenInputMessageSuccess {
  color: var(--app-green, #3a7d44);
  display: flex;
}

.ProfileOpenInputMessageError {
  color: var(--Danger-color-red, red);
  display: flex;
}
/*# sourceMappingURL=index.5faa2144.css.map */
